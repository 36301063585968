export const whatsNew = [
    {
      Name:"Kota Fatory 2",
      Platform : "Netflix",
      TrailerLink : "https://www.youtube.com/embed/aG8KWYko8VY?si=TMOEmdigmcrkP9p6"
    },
    {
        Name:"The Boys Season 3",
        Platform : "Prime Video",
        TrailerLink : "https://www.youtube.com/embed/EzFXDvC-EwM?si=ZGKYu15PU8-W0uyQ"

    },
]